.kb-page--index main {
    margin-bottom: -6.675em;
    padding-bottom: 6.675em;
}
body>main {
    -webkit-box-flex: 1;
  
    flex: 1 0 auto;
}
.content-container {
    position: relative;
    width: 100%;
    max-width: 1092px;
    margin: 0 auto !important;
    padding: 0 20px;
    float: none !important;
}
.kb-index {
  
  
    display: flex;
   
    flex-wrap: wrap;
    -webkit-box-pack: justify;

    justify-content: space-between;

    margin: 0 auto;

}
.kb-index--tiles {
    padding: 4.125rem 0 0;
}
@media screen and (min-width: 541px){
.kb-index {
    max-width: 680px;
}}
@media screen and (min-width: 1045px){
.kb-index {
    max-width: 1052px;
}}
@media screen and (min-width: 730px){
.kb-index--tiles {
    max-width: 680px;
}}


@media screen and (min-width: 1070px){
.kb-index--tiles {
    max-width: 1052px;
}}
@media screen and (min-width: 768px){
.kb-index:after {
    -webkit-box-flex: 0;
    -ms-flex: 0 330px;
    flex: 0 330px;
}}


@media screen and (min-width: 1070px){
.kb-index--tiles {
    max-width: 1052px;
}}
@media screen and (min-width: 730px){
.kb-index--tiles {
    max-width: 680px;
}}
@media screen and (min-width: 1045px){
.kb-index {
    max-width: 1052px;
}}
@media screen and (min-width: 541px){
.kb-index {
    max-width: 680px;
}}
.kb-index--tiles {
    padding: 4.125rem 0 0;
}
.kb-index {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1052px;
    margin: 0 auto;
    padding: 4rem 0 0;
}
* {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
}
body {
    margin: 0;
    color: #33475b;
    font-family: Helvetica,Arial,sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
    word-break: break-word;
}
@media screen and (min-width: 768px){
.kb-index:after {
    -webkit-box-flex: 0;
    -ms-flex: 0 330px;
    flex: 0 330px;
}}
@media screen and (min-width: 541px){
.kb-index:after {
    -webkit-box-flex: 0;
    -ms-flex: 0 332px;
    flex: 0 332px;
}}
.kb-index--tiles .kb-index__category {
    padding: 4.25rem 1rem 3.25rem;
    margin-bottom: 2.6875rem;
    text-align: center;
    color: #2d3e50;
    border: 1px solid #eaf0f6;
    border-radius: 3px;
    background-color: #fff;
    width: 332px;
    min-height: 330px;
    flex: 0 auto;
    &:hover {
        color:red;
    }
}
@media screen and (min-width: 768px){
.kb-index__category--3col {
    -webkit-box-flex: 0;
    -ms-flex: 0 330px;
    flex: 0 330px;
}}
.kb-index--tiles .kb-index__category {
    padding: 4.25rem 1rem 3.25rem;
    margin-bottom: 2.6875rem;
    text-align: center;
    color: #2d3e50;

    border-radius: 3px;
    background-color: #fff;
    width: 332px;
    min-height: 330px;
    flex: 0 auto;
}
.kb-index--tiles .kb-index__category-icon, .kb-index--tiles .kb-index__category-empty-preview-icon {
    font-size: 54px;
    height: 4.625rem;
    margin: 0 auto 1.5rem auto;
    width: auto;
    max-width: 100%;
}
.kb-theme--tiles .kb-search-section--full {
    min-height: 390px;
    padding: 3.75rem 0;
}
.kb-search-section-with-image {
    background-image: url(https://help.centiment.co/hubfs/centiment-hubpost-cover@2x-min-1.png);
    background-size: cover;
    color: #ffffff;
    position: relative;
    height:350px;
}
.kb-search-section {
    background-color: #ffffff;
}
.kb-search-section-with-image {
    background-position: center center;
}
.kb-search-section {

    display: flex;
    -webkit-box-align: end;

    align-items: flex-end;
}
.kb-theme--tiles .kb-search-section--full .kb-search-section__content {
    max-width: 693px;
}

.kb-theme--tiles .kb-search-section--full .kb-search-section__content {
    max-width: 693px;
}
.content-container {
    position: relative;
    width: 100%;
    max-width: 1092px;
    margin: 0 auto !important;
    padding: 0 20px;
    float: none !important;
}
.kb-search-section--full .kb-search, .kb-search-section--full .kb-search-section__title {
    max-width: 693px;
}
.kb-theme--tiles .kb-search-section__title {
    justify-content: center;
    text-align: center;
}
.kb-search-section__title {
    color: #FFFFFF;
}
.kb-search-section__title {
    margin: 0 0 1rem;
 
    display: flex;
    -webkit-box-pack: justify;
}
.kb-search-section__title {
    color: #FFFFFF;

}
h1, h2, h3, h3 a, h4, h4 a, h5, h6, .kb__text-link, .kb__text-link-small, .kb-header, .kb-sticky-footer, .kb-search__suggestions__article-title, .kb-search-results__title, #hs_form_target_kb_support_form input.hs-button, #hs_form_target_kb_support_form label, input, select, textarea {
    font-family: helvetica;
}
h1 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.75rem;
}
.kb-theme--tiles .kb-search-section__title {

    text-align: center;
}
.kb-search-section--full .kb-search, .kb-search-section--full .kb-search-section__title {
    max-width: 693px;
}
.kb-theme--tiles .kb-search {
    margin: 0 auto;
}
.kb-search {
    position: relative;
}
.kb-search__placeholder {
    height: 50px;
    width: 100%;
}
.kb-search__box {
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border: 1px solid transparent;
    box-shadow: 0 1px 24px 0 rgba(0,0,0,.08);
}
.kb-search__bar {
    min-height: 48px;
   
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.kb-search__mag {
    margin-left: 15px;
}
.kb-search form {
    -webkit-box-flex: 1;
   
    flex: 1;
}
form {
    display: block;
    margin-top: 0em;
}
svg:not(:root) {
    overflow: hidden;
}
.kb-search__close {
    margin-right: 15px;
    opacity: 0;
    pointer-events: none;
}
.kb-search__suggestions {
    margin: 0;
    padding: 0;
    list-style: none;
}
@media screen and (min-width: 768px){
.kb-search__suggestions {
    display: block;
}}
.kb-search__bar {
    min-height: 48px;
  
    display: flex;
    align-items: center;
}
.kb-search__input {
    width: 100%;
    box-sizing: border-box;
    font-size: 1em;
    padding: .75em;
    border: 0;
    color: #7c98b6;
    transition: color .1s ease-in;
}
.kb-theme--tiles .kb-search-section__title {
    justify-content: center;
    text-align: center;
}
.kb-search-section {

    display: flex;
  
   
    padding: 6.25rem;
    color: #fff;



}
.kb-theme--tiles .kb-search-section--full {
    min-height: 390px;
    padding: 14.75rem 0;
}
/*
    Created on : Dec 13, 2020, 12:31:57 PM
    Author     : Faizan
*/

@font-face {
    font-family: TTNorms-Black;
    src: url(../fonts/TTNorms-Black.otf);
}

@font-face {
    font-family: TTNorms-Bold;
    src: url(../fonts/TTNorms-Bold.otf);
}

@font-face {
    font-family: TTNorms-Light;
    src: url(../fonts/TTNorms-Light.otf);
}

@font-face {
    font-family: TTNorms-Regular;
    src: url(../fonts/TTNorms-Regular.otf);
}

@font-face {
    font-family: "TT Norms Pro Regular";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Regular"),
        url("../fonts/tt_norms_pro/TT Norms Pro Regular.woff") format("woff");
    font-family: 'TT Norms Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Regular'), url('../fonts/tt_norms_pro/TT Norms Pro Regular.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Italic'), url('../fonts/tt_norms_pro/TT Norms Pro Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Thin';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Thin'), url('../fonts/tt_norms_pro/TT Norms Pro Thin.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Thin Italic'), url('../fonts/tt_norms_pro/TT Norms Pro Thin Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro ExtraLight'), url('../fonts/tt_norms_pro/TT Norms Pro ExtraLight.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro ExtraLight Italic'), url('../fonts/tt_norms_pro/TT Norms Pro ExtraLight Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Light';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Light'), url('../fonts/tt_norms_pro/TT Norms Pro Light.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Light Italic'), url('../fonts/tt_norms_pro/TT Norms Pro Light Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Medium';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Medium'), url('../fonts/tt_norms_pro/TT Norms Pro Medium.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Medium Italic'), url('../fonts/tt_norms_pro/TT Norms Pro Medium Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Bold'), url('../fonts/tt_norms_pro/TT Norms Pro Bold.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Bold Italic'), url('../fonts/tt_norms_pro/TT Norms Pro Bold Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro ExtraBold'), url('../fonts/tt_norms_pro/TT Norms Pro ExtraBold.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro ExtraBold Italic'), url('../fonts/tt_norms_pro/TT Norms Pro ExtraBold Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Black';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Black'), url('../fonts/tt_norms_pro/TT Norms Pro Black.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro Black Italic'), url('../fonts/tt_norms_pro/TT Norms Pro Black Italic.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro ExtraBlack';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro ExtraBlack'), url('../fonts/tt_norms_pro/TT Norms Pro ExtraBlack.woff') format('woff');
}


@font-face {
    font-family: 'TT Norms Pro ExtraBlack Italic';
    font-style: normal;
    font-weight: normal;
    src: local('TT Norms Pro ExtraBlack Italic'), url('../fonts/tt_norms_pro/TT Norms Pro ExtraBlack Italic.woff') format('woff');
}

@font-face {
    font-family: "TT Norms Pro Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Thin";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Thin"),
        url("../fonts/tt_norms_pro/TT Norms Pro Thin.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Thin Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Thin Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro Thin Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro ExtraLight";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro ExtraLight"),
        url("../fonts/tt_norms_pro/TT Norms Pro ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro ExtraLight Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro ExtraLight Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro ExtraLight Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Light";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Light"),
        url("../fonts/tt_norms_pro/TT Norms Pro Light.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Light Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Light Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro Light Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Medium";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Medium"),
        url("../fonts/tt_norms_pro/TT Norms Pro Medium.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Medium Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Medium Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro Medium Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Bold";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Bold"),
        url("../fonts/tt_norms_pro/TT Norms Pro Bold.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Bold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Bold Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro Bold Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro ExtraBold";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro ExtraBold"),
        url("../fonts/tt_norms_pro/TT Norms Pro ExtraBold.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro ExtraBold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro ExtraBold Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro ExtraBold Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Black";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Black"),
        url("../fonts/tt_norms_pro/TT Norms Pro Black.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro Black Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro Black Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro Black Italic.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro ExtraBlack";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro ExtraBlack"),
        url("../fonts/tt_norms_pro/TT Norms Pro ExtraBlack.woff") format("woff");
}

@font-face {
    font-family: "TT Norms Pro ExtraBlack Italic";
    font-style: normal;
    font-weight: normal;
    src: local("TT Norms Pro ExtraBlack Italic"),
        url("../fonts/tt_norms_pro/TT Norms Pro ExtraBlack Italic.woff") format("woff");
}

body {
    font-family: TTNorms-Regular;
}

#page_content {
    .mobile_first {
        h1 {
            text-align: center;
            font-size: 44px;
            color: #351d48;
            line-height: 51px;
            font-family: TTNorms-Bold;
            padding-top: 40px;
            margin-bottom: 70px;
        }

        .card {
            border: none;

            .card-body {
                h2 {

                    font-size: 29px;
                    color: #351d48;
                    padding: 5px 0;
                    font-family: TTNorms-Bold;

                }

                p {
                    color: #616161;
                    font-family: TTNorms-Regular;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    .manage_setup {
        padding: 30px 0;
        background-color: #f8f8f8;

        h1 {
            font-size: 40px;
            color: #351d48;
            line-height: 51px;
            font-family: TTNorms-Bold;
            font-weight: 800;
        }

        p {
            color: #616161;
            font-size: 16px;
            font-family: TTNorms-Regular;
            padding-top: 15px;
            line-height: 23px;
        }

        .btn {
            background-color: #fff;
            color: #760fc3;
            font-weight: 600;
            font-size: 18px;
            padding: 14px 30px;
            border: 1px solid #760fc3;
            transition: 0.3s;

            &:hover {
                color: #fff;
                background-color: #760fc3;
            }
        }

        .card {
            border: none;
            box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, .06);
            margin: 10px 0;

            .card-body {
                h5 {
                    font-family: TTNorms-Bold;
                    color: #351d48;
                    font-size: 18px;
                    margin-left: -16px;
                }

                .sv {
                    padding: 0;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    border: 1px solid #ececec;
                    border-radius: 16px;
                    width: 50%;
                }

            }
        }
    }

    .mobile {
        background-color: #f8f8f8;
        /*margin-top: 60px;*/
        padding: 40px 0;

        h1 {
            font-size: 50px;
            color: #351d48;
            text-align: center;
            font-family: TTNorms-Bold;
            font-weight: 800;
        }

        p {
            text-align: center;
            padding: 8px 0;
            font-size: 20px;
            color: #616161;
            font-family: TTNorms-Regular;
            font-weight: 600;
        }

        .btn {
            background-color: #760fc3;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            padding: 14px 30px;
            margin-bottom: 15px;
            border: 1px solid #760fc3;
            transition: 0.3s;

            &:hover {
                color: #760fc3;
                background-color: #fff;
            }
        }

        .want {
            text-align: center;
            color: #616161;
            font-family: TTNorms-Regular;
            font-weight: 400;
            padding: 8px 0;
            font-size: 17px;
        }

        a {
            color: #760fc3;
            font-family: TTNorms-Regular;
            font-weight: 600;
            text-decoration: none;
            transition: 0.3s;

            &:hover {
                color: #616161;
            }
        }
    }

    .home_banner {
        font-family: 'Montserrat';

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 300px 0;

        h1 {
            font-size: 3.75rem;
            font-weight: 600;
            margin-bottom: 1.25rem;
            text-align: center;
            color: #fff;
            font-family: 'Montserrat';
        }

        p {
            font-size: 1.875rem;
            color: #fff;
            font-family: 'Montserrat';
            margin-bottom: 4rem;
            text-align: center;
        }

        img {
            margin-bottom: 4rem;
            text-align: center;
        }

        .hero-buttons {
            text-align: center;

            .btn-default {
                font-family: 'Montserrat';
                color: #fff;
                border-color: hsla(0, 0%, 100%, .5);
                border: 1px solid;
                font-size: .875rem;
                font-weight: 500;
                padding: .75rem 1.75rem;
                text-transform: uppercase;
                border-radius: 2rem;

                &:hover {
                    border-color: #fff;
                }
            }

            .btn+.btn {
                margin-left: 1.5rem;
            }

            .btn-primary {
                font-family: 'Montserrat';
                background-color: rgba(0, 0, 0, .3);
                border-color: transparent;
                font-size: .875rem;
                font-weight: 500;
                padding: .75rem 1.75rem;
                text-transform: uppercase;
                border-radius: 2rem;

                &:hover {
                    background-color: rgba(0, 0, 0, .5);
                }
            }
        }
    }

    .world {
        font-family: 'Montserrat';

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 300px 0;

        h2 {
            font-family: 'Montserrat';
            color: #fff;
            font-weight: 500;
            text-align: center;
            margin-bottom: 40px;
            font-size: 30px;
        }

        p {
            font-family: 'Montserrat';
            color: #fff;
            font-weight: 400;
            text-align: center;
            font-size: 18px;
        }

    }

    .scribble {
        font-family: 'Montserrat';

        padding: 100px 0;

        h1 {
            font-family: 'Montserrat';
            color: #000;
            text-align: center;
            font-weight: 800;
            padding-bottom: 50px;
            font-size: 65px;
        }

        .card {
            border: none;

            .card-body {
                padding: 0;

                img {
                    display: block;
                    margin: 0 auto;
                }

                .card-text {
                    h4 {
                        font-family: 'Montserrat';
                        color: #000;
                        font-weight: 500;
                        text-align: center;
                        font-size: 28px;
                        padding-top: 30px;
                    }

                    p {
                        font-family: 'Montserrat';
                        color: #000;
                        font-weight: 400;
                        padding-top: 15px;
                        text-align: center;

                    }
                }
            }

        }
    }

    .order {
        font-family: 'Montserrat';

        background-color: #f8f9fc;
        padding: 20px 0;

        .cover {
            font-family: 'Montserrat';
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        p {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 700;
            font-size: 14px;
            position: relative;
            margin: 0;
        }

        .btn {
            font-family: 'Montserrat';
            margin-left: 20px;
            background-color: #f8f9fc;
            color: #000;
            border: 1px solid #000;
            padding: 10px 30px;
            font-weight: 500;
            border-radius: 28px;
            transition: 0.3s;

            &:hover {
                background-color: #333333;
                color: #fff;
            }
        }
    }

    .last_pen {
        font-family: 'Montserrat';

        padding: 80px 0;

        h1 {
            font-family: 'Montserrat';
            color: #000;
            font-size: 60px;
            font-weight: 700;
        }

        h4 {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 500;
            font-size: 28px;
            padding-top: 30px;
        }

        p {
            font-family: 'Montserrat';
            font-weight: 400;
            padding-top: 10px;
            font-size: 18px;
        }
    }

    .inspired {
        font-family: 'Montserrat';

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 150px 0;

        h2 {
            font-family: 'Montserrat';
            color: #fff;
            font-weight: 400;
            text-align: center;
            padding: 20px 0;
            font-size: 68px;
        }

        p {
            font-family: 'Montserrat';
            text-align: center;
            color: #fff;
            font-weight: 400;
            font-size: 19px;
            padding-bottom: 20px;
        }

        .btn {
            font-family: 'Montserrat';
            color: #fff;
            background-color: transparent;
            padding: 10px 30px;
            border-radius: 28px;
            font-weight: 500;
            font-size: 18px;
            border: 1px solid #fff;
            transition: 0.3s;

            &:hover {
                color: #333333;
            }
        }
    }

    .ink_pen {
        font-family: 'Montserrat';

        background-color: #f8f9fc;
        padding-bottom: 50px;

        img {
            width: 80%;
        }

        .container-fluid {
            padding: 0;

            .row {
                margin: 0;

                .col-12 {
                    padding: 0;
                }
            }
        }

        h3 {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 300;
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 50px;

        }

        h2 {
            color: #000;
            font-weight: 500;
            font-size: 35px;
            margin-bottom: 30px;
            font-family: 'Montserrat';
        }

        .list-group {
            .list-group-item {
                background-color: transparent;
                padding: 15px 6px;
                font-size: 19px;
                font-weight: 300;
                font-family: 'Montserrat';
            }
        }

        .sx {
            margin-left: -91px;

        }
    }

    .paper {
        font-family: 'Montserrat';

        padding: 50px 0;

        h1 {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 55px;
            color: #000;
            padding-bottom: 20px;
        }

        h4 {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 400;
            font-size: 30px;
            padding: 10px 0;
        }

        p {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 300;
            font-size: 18px;

        }

        img {
            width: 90%;
            margin: 0 auto;
            display: block;
        }

    }

    .specification {
        font-family: 'Montserrat';

        background-color: #424654;
        padding: 100px 0;
        color: #fff;

        h3 {

            font-family: 'Montserrat';
            font-weight: 300;
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 50px;

        }

        h2 {

            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 35px;
            margin-bottom: 30px;
        }

        .list-group {
            .list-group-item {
                background-color: transparent;
                padding: 15px 6px;
                font-family: 'Montserrat';
                font-size: 19px;
                border-color: #666666;
                font-weight: 300;
            }
        }

        img {
            display: block;
        }
    }

    .imagination {
        font-family: 'Montserrat';

        padding: 150px 0 750px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left bottom;

        h1 {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 700;
            font-size: 65px;
            padding-bottom: 30px;
        }

        h4 {
            font-family: 'Montserrat';
            color: #000;
            font-size: 20px;

            font-weight: 500;
        }

        p {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 400;
            padding-top: 20px;
            font-size: 20px;
        }

        img {
            display: block;
            /*            position: relative;
                    top: -15%;
                    left: 9%;*/
        }
    }

    .home {
        font-family: 'Montserrat';

        margin: 80px 0 50px;
        padding: 26px 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h4 {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 30px;

        }

        p {
            font-family: 'Montserrat';
            color: #000;
            font-size: 19px;
            font-weight: 400;

        }
    }

    .featured {
        font-family: 'Montserrat';

        background-color: #424654;
        padding: 100px 0;

        h3 {
            font-family: 'Montserrat';
            color: #fff;
            font-size: 60px;
            font-weight: 300;
            padding: 30px 0;
            text-align: center;
        }

        img {
            height: 80px;
            width: 10.9375rem;
            margin: 30px 0;
        }
    }

    .mail {
        font-family: 'Montserrat';

        background-color: #f8f9fc;
        padding: 20px 0;

        .cover {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        p {
            font-family: 'Montserrat';
            color: #000;
            font-weight: 700;
            font-size: 14px;
            position: relative;
            margin: 0;
        }

        .btn {
            font-family: 'Montserrat';
            margin-left: 20px;
            background-color: #53abe0;
            color: #fff;
            font-weight: 400;
            font-size: 18px;
            padding: 10px 30px;
            border-radius: 28px;
            border: 1px solid #53abe0;
            transition: 0.3s;

            &:hover {
                background-color: #fff;
                color: #53abe0;
            }
        }
    }

    .both {
        font-family: 'Montserrat';

        background-color: #f8f9fc;
        padding: 150px 0;

        h2 {
            font-family: 'Montserrat';
            color: #000;
            text-align: center;
            font-weight: 300;
            margin-bottom: 50px;
            font-size: 60px;
        }

        .card {
            border: none;
            height: 100%;

            .card-body {
                padding: 30px 30px;

                .card-text {
                    h4 {
                        font-family: 'Montserrat';
                        color: #000;
                        font-size: 30px;
                        font-weight: 400;
                        margin-bottom: 30px;

                        strong {
                            font-weight: 600;
                        }
                    }

                    p {
                        font-family: 'Montserrat';
                        color: #000;
                        font-size: 20px;
                        font-weight: 400;
                        padding: 2px 0;
                    }

                    img {
                        display: block;
                        margin: 10px auto;
                    }

                }

                .nav {
                    display: flex;
                    padding: 10px 20px;
                    justify-content: space-between;

                    a {
                        font-family: 'Montserrat';
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }

                    .bla {
                        background-color: #000;
                    }

                    .whi {
                        background-color: #fff;
                        border: 1px solid #999999;
                    }

                    .gre {
                        background-color: #e6e6e6;
                    }

                    .blu {
                        background-color: #53abe0;
                        border-radius: 50%;
                    }

                    .gren {
                        background-color: #8dc24c;
                        border-radius: 50%;
                    }
                }

                .btn {
                    font-family: 'Montserrat';
                    background-color: #fff;
                    color: #53abe0;
                    font-weight: 400;
                    font-size: 18px;
                    padding: 10px 30px;
                    margin-top: 30px;
                    font-weight: 500;
                    margin-bottom: 30px;
                    border-radius: 28px;
                    border: 1px solid #53abe0;
                    transition: 0.3s;

                    &:hover {
                        color: #fff;
                        background-color: #53abe0;
                    }
                }

                .img {
                    display: block;
                    margin: 44px auto;
                }

                .price {
                    font-family: 'Montserrat';
                    text-align: center;
                    font-weight: 400;
                    color: #000;
                    font-size: 17px;

                }
            }
        }
    }

    .shop,
    .upgrade,
    .fly {
        padding: 100px 0;

        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 60px;
            color: #000;
        }

        .card {
            border: none;
            background-color: transparent;

            .card-body {
                img {
                    display: block;
                    margin: 0 auto;

                    transition: 0.3s;

                    &:hover {
                        transform: translateY(-20px);
                    }
                }

                .card-text {
                    text-align: center;

                    h4 {
                        font-weight: 500;
                        padding-top: 20px;
                    }

                    p {
                        font-weight: 500;
                    }

                    span {
                        text-align: center;
                        display: block;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .sec_1 {
        padding: 100px 0 80px;

        h1 {
            font-family: TTNorms-Bold;
            line-height: 1.2em;
            color: #351d48;
            font-size: 66px;
            letter-spacing: 0.28px;
            position: relative;
            margin-bottom: 40px;
            padding-bottom: 20px;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 6px;
                width: 100px;
                background-color: #0dd3c5;
            }
        }

        p {
            font-family: TTNorms-Bold;
            font-size: 19px;
        }
    }

    .sec_2 {
        padding: 80px 0;

        h3 {
            font-family: TTNorms-Bold;
            line-height: 1.2em;
            color: #351d48;
            font-size: 48px;
            text-align: center;
            letter-spacing: 0.28px;
            position: relative;
            margin-bottom: 60px;
        }

        .card {
            border: 0;
            background-color: transparent;
            text-align: center;

            img {
                max-height: 330px;
                width: 60%;
                margin: 0 auto;
                margin-bottom: 20px;
            }

            .card-body {
                h4 {
                    font-family: TTNorms-Bold;
                    line-height: 1.2em;
                    color: #351d48;
                    font-size: 25px;
                    text-align: center;
                    letter-spacing: 0.28px;
                }

                p {
                    color: #444;
                    font-size: 19px;
                    font-family: TTNorms-Regular;
                }
            }
        }
    }

    .sec_3 {
        padding: 80px 0;

        &.vw {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            h4 {
                font-family: TTNorms-Bold;
                line-height: 1.2em;
                color: #351d48;
                font-size: 33px;
                color: #fff;
                text-align: center;
                letter-spacing: .28px;
                position: relative;
                margin-bottom: 60px;
            }
        }

        h3 {
            font-family: TTNorms-Bold;
            line-height: 1.2em;
            color: #351d48;
            font-size: 48px;
            text-align: center;
            letter-spacing: 0.28px;
            position: relative;
            margin-bottom: 60px;
        }

        .div-block-25 {
            display: flex;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 50px;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        img {
            height: 40px;
            margin: 30px 20px;
        }

        .btn {
            background-color: #0dd3c5;
            padding: 10px 30px;
            color: #fff;
            font-family: TTNorms-Regular;
            font-size: 20px;
            font-weight: 600;
            border: 1px solid #351d48;
            transition: 0.3s all;

            &:hover {
                background-color: #351d48;
                color: #fff;
                border-color: #fff;
            }

        }
    }

    .mobile_first_banner {
        padding: 200px 0;

        h1 {
            margin-bottom: 15px;
            color: #fff;
            font-size: 66px;
            line-height: 1.2em;
            font-weight: 700;
            letter-spacing: .28px;
        }

        p {
            padding-top: 20px;
            padding-bottom: 20px;
            font-family: TTNorms, sans-serif;
            color: #fff;
            font-size: 25px;
            font-weight: 400;
        }


        .btn {
            height: 64px;
            background-color: #0dd3c5;
            padding: 10px 30px;
            flex: auto;
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: TTNorms-Regular;
            font-size: 20px;
            font-weight: 600;
            border: 1px solid transparent;
            transition: 0.3s all;

            &:hover {
                border-color: #00968c;
                background-color: #00968c;
                color: #fff;
            }

        }

        .btn_2 {
            margin-left: 24px;
            flex: auto;
            border: 1px solid transparent;
            background-color: hsla(0, 0%, 100%, .2);
            color: #fff;

            &:hover {
                border-color: rgba(255, 197, 0, .5);
                background-color: rgba(255, 197, 0, .8);
            }
        }

    }
}

.atss {
    position: fixed;
    top: 20%;
    width: 3pc;
    z-index: 1020;
    background: none;

    .at-share-btn {
        position: relative;
        display: block;
        width: 3pc;
        height: 3pc;
        margin: 0;
        outline-offset: -1px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        float: left;
        transition: width .15s ease-in-out;
        overflow: hidden;
        text-decoration: none;
        color: #fff;
        z-index: 100030;
        cursor: pointer;
        border: 0;
        padding: 0;

        &.at-svc-facebook {
            background-color: rgb(59, 89, 152);
        }

        &.at-svc-twitter {
            background-color: rgb(29, 161, 242);
        }

        &.at-svc-email {
            background-color: rgb(132, 132, 132);
        }

        &.at-svc-pinterest_share {
            background-color: rgb(203, 32, 39);
        }

        &.at-svc-compact {
            background-color: rgb(255, 101, 80);
        }

        &:focus {
            width: 4pc;
        }

        &:hover {
            width: 4pc;
        }

        .at-icon-wrapper {
            display: block;
            padding: 8px 0;
        }

        &:last-child {
            border: none;
        }
    }
}

@media (max-width: 1199.98px) {
    #page_content .mobile_first_banner h1 {
        font-size: 50px;
    }

    #page_content .mobile_first_banner p {
        font-weight: 400;
    }

    #page_content .sec_1 h1 {
        font-size: 55px;
    }

    #page_content .world {
        padding: 150px 0;
    }

    #page_content .scribble {
        padding: 70px 0;
    }

    #page_content .scribble h1 {
        font-size: 45px;
    }

    #page_content .scribble .card .card-body .card-text h4 {
        font-size: 22px;
        padding-top: 15px;
    }

    #page_content .scribble .card .card-body .card-text p {
        font-size: 13px;
        padding-top: 10px;
    }

    #page_content .last_pen h1 {
        font-size: 50px;
    }

    #page_content .inspired {
        padding: 70px 0;
    }

    #page_content .ink_pen .list-group .list-group-item {
        font-size: 16px;
    }

    #page_content .imagination {
        padding: 70px 0 650px;
    }

    #page_content .home {
        margin: 50px 0 0;
        padding: 26px 0;
    }

    #page_content .featured {
        background-color: #424654;
        padding: 70px 0;
    }

    #page_content .both {
        padding: 70px 0;
    }
}

@media (max-width: 991.98px) {
    #page_content .mobile_first_banner h1 {
        font-size: 30px;
        text-align: center;
    }

    #page_content .mobile_first_banner {
        padding: 120px 0;
    }

    #page_content .mobile_first_banner p {
        font-size: 18px;
        text-align: center;
    }

    #page_content .mobile_first_banner img {
        margin: 20px 0;
        display: block;
    }

    #page_content .home {
        margin: 0px 0 0;
        padding: 26px 0;
    }

    #page_content .scribble {
        padding: 50px 0;
    }

    #page_content .scribble .card .card-body .card-text h4 {
        font-size: 16px;
    }

    #page_content .scribble .card .card-body .card-text p {
        font-size: 11px;
    }

    #page_content .order p {
        font-size: 12px;
    }

    #page_content .order .btn {
        padding: 10px 20px;
        font-size: 12px;
    }

    #page_content .last_pen h1 {
        font-size: 35px;
    }

    #page_content .last_pen p {
        font-size: 14px;
    }

    #page_content .last_pen h4 {
        font-size: 20px;
        padding-top: 16px;
        margin: 0;
    }

    #page_content .inspired h2 {
        font-size: 25px;
    }

    #page_content .inspired p {
        font-size: 12px;
    }

    #page_content .inspired .btn {
        padding: 7px 20px;
        font-size: 15px;
    }

    #page_content .ink_pen {
        padding: 50px 0;
    }

    #page_content .ink_pen h3 {
        font-size: 35px;
    }

    #page_content .ink_pen h2 {
        font-size: 25px;
    }

    #page_content .ink_pen .list-group .list-group-item {
        font-size: 12px;
        padding: 10px 6px
    }

    #page_content .paper h1 {
        font-size: 40px;
    }

    #page_content .paper h4 {
        font-size: 25px;
    }


    #page_content .paper p {
        font-size: 14px;
    }

    #page_content .specification {
        padding: 70px 0;
    }

    #page_content .specification h3 {
        font-size: 40px;
    }

    #page_content .specification h2 {
        font-size: 25px;
    }

    #page_content .specification .list-group .list-group-item {
        padding: 10px 6px;
        font-size: 12px;
    }

    #page_content .imagination h1 {
        font-size: 40px;
        padding: 0;
    }

    #page_content .imagination h3 {
        font-size: 25px;

        br {
            display: contents;
        }
    }

    #page_content .imagination p {
        padding: 0;
    }

    #page_content .imagination {
        padding: 50px 0 550px;
    }

    #page_content .home h4 {
        font-size: 22px;
        padding-bottom: 17px;
    }

    #page_content .home p {
        font-size: 14px;
    }

    #page_content .featured img {
        height: auto;
        width: 50%;
        margin: 30px auto;
        display: block;
    }

    #page_content .both h2 {
        font-size: 40px;
    }

    #page_content .both .card .card-body {
        padding: 10px;
    }

    #page_content .both .card .card-body .card-text h4 {
        margin-left: 0;
    }

    #page_content .both .card .card-body .card-text p {
        margin-left: 0;
        font-size: 14px;
    }

    #page_content .both .card .card-body .nav .bla {
        margin-left: 0px;
    }

    #page_content .both .card {
        height: 100%;
    }

    #page_content .both {
        padding: 50px 0;
    }

    #page_content .fly .card .card-body .card-text h4,
    #page_content .shop .card .card-body .card-text h4,
    #page_content .upgrade .card .card-body .card-text h4 {
        font-size: 20px;
    }

    #page_content .fly .card .card-body .card-text p,
    #page_content .shop .card .card-body .card-text p,
    #page_content .upgrade .card .card-body .card-text p {
        font-size: 14px;
    }

    #page_content .sec_1 h1 {
        font-size: 40px;
    }

    #page_content .sec_1 p {
        font-size: 14px;
    }

    #page_content .sec_2 .card .card-body p {
        color: #444;
        font-size: 16px;
        font-family: TTNorms-Regular;
    }

    #page_content .sec_2 .card .card-body p br {
        display: contents;
    }

    #page_content .sec_2,
    #page_content .sec_3,
    #page_content .sec_1 {
        padding: 50px 0;
    }
}

@media (max-width: 767.98px) {
    #page_content .mobile_first_banner h1 {
        font-size: 24px;
        text-align: center;
    }

    #page_content .mobile_first_banner p {
        font-size: 16px;
        text-align: center;
    }

    #page_content .mobile_first_banner {
        padding: 80px 0;
    }

    #page_content .home_banner {
        padding: 70px 0;
    }

    #page_content .home_banner h1 {
        font-size: 2.75rem;
    }

    #page_content .home_banner p {
        font-size: 0.875rem;
    }

    #page_content .world {
        padding: 50px 0;
    }

    #page_content .world p br {
        display: contents;
    }

    #page_content .scribble h1 {
        font-size: 30px;
    }

    #page_content .order p {
        font-size: 10px;
    }

    #page_content .order .btn {
        padding: 10px 20px;
        font-size: 9px;
    }

    #page_content .last_pen h1 {
        font-size: 23px;
    }

    #page_content .inspired h2 {
        font-size: 21px;
    }

    #page_content .paper h1 {
        font-size: 25px;
    }

    #page_content .specification .list-group {
        padding: 0 0 30px 0;
    }

    #page_content .imagination h1 {
        font-size: 28px;
    }

    #page_content .imagination h1 {
        font-size: 28px;
    }

    #page_content .imagination p {
        font-size: 16px;
    }

    #page_content .imagination p br {
        display: contents;
    }

    #page_content .imagination {
        padding: 50px 0 500px;
    }

    #page_content .home {
        background-image: unset;
        background-color: #fff;
    }

    #page_content .featured {
        padding: 50px 0;
    }

    #page_content .featured h3 {
        padding: 0;
    }

    #page_content .both h2 {
        font-size: 30px;
    }

    #page_content .both .card {
        height: auto;
        margin-bottom: 20px;
    }

    #page_content .fly .card .card-body img,
    #page_content .shop .card .card-body img,
    #page_content .upgrade .card .card-body img {
        width: 100%;
    }

    #page_content .sec_1 h1 {
        font-size: 30px;
    }

    #page_content .sec_2 .card img {
        min-height: auto;
    }

    #page_content .sec_1,
    #page_content .sec_2,
    #page_content .sec_3 {
        padding: 35px 0;
    }

    #page_content .sec_3 img {
        height: 30px;
        margin: 20px 10px;
    }
}

@media(max-width:575.98px) {
    #page_content .mobile_first_banner h1 {
        font-size: 18px;
        margin-bottom: 0;
        text-align: center;
    }

    #page_content .mobile_first_banner .btn {
        flex: unset;
        width: 100%;
    }

    #page_content .mobile_first_banner .text-center.d-flex.justify-content-center {
        flex-direction: column;
    }

    #page_content .mobile_first_banner .btn_2 {
        margin: 20px 0 0 0;
    }

    #page_content .mobile_first_banner p {
        padding: 20px 0 5px 0;
    }

    #page_content .mobile_first_banner .btn {
        height: auto;
        font-size: 16px;
        padding: 10px 10px;
    }

    #page_content .home_banner {
        padding: 50px 0;
    }

    #page_content .home_banner h1 {
        font-size: 1.5rem;
    }

    #page_content .home_banner p {
        font-size: 0.75rem;
    }

    #page_content .home_banner .hero-buttons .btn-default {
        padding: 10px 15px;
    }

    #page_content .home_banner .hero-buttons .btn+.btn {
        margin-left: 5px;
    }

    #page_content .home_banner .hero-buttons .btn-primary {
        padding: 10px 15px;
    }

    #page_content .imagination {
        padding: 50px 0 400px;
    }

    #page_content .order .cover {
        flex-direction: column;
    }

    #page_content .order .cover p {
        margin-bottom: 20px;
    }

    #page_content .last_pen,
    #page_content .inspired {
        padding: 50px 0;
    }

    #page_content .last_pen h1 {
        font-size: 17px;
    }

    #page_content .last_pen h4 {
        font-size: 14px;
    }

    #page_content .last_pen p {
        font-size: 11px;
    }

    #page_content .inspired h2 {
        font-size: 16px;
        padding: 0;
        font-weight: 700;
    }

    #page_content .inspired p {
        font-size: 11px;
        padding: 0;
    }

    #page_content .paper h1 {
        font-size: 20px;
        padding: 0;
    }

    #page_content .paper h4 {
        font-size: 16px;
    }

    #page_content .paper p {
        font-size: 12px;
    }

    #page_content .imagination h1 {
        font-size: 22px;
    }

    #page_content .imagination h3 {
        font-size: 18px;
    }

    #page_content .imagination p {
        font-size: 13px;
    }

    #page_content .imagination {
        padding: 50px 0 350px;
    }

    #page_content .home h4 {
        font-size: 19px;
        padding-bottom: 0;
    }

    #page_content .featured h3 {
        font-size: 40px;
    }

    #page_content .mail .cover {
        flex-direction: column;
    }

    #page_content .mail .cover p {
        margin-bottom: 20px;
    }

    #page_content .both h2 {
        font-size: 22px;
    }

    #page_content .both .card .card-body .card-text h4 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    #page_content .both .card .card-body .nav a {
        margin-left: 5px !important;
    }

    #page_content .featured img {
        width: 70%;
    }

    #page_content .mobile_first h1,
    #page_content .mobile h1 {
        font-size: 26px;
        line-height: normal;
    }

    #page_content .mobile_first .card .card-body img {
        width: 50%;
        margin: 0 auto;
        display: block;
    }

    #page_content .sec_3.vw h4 {
        font-size: 23px;
        margin-bottom: 20px;
    }

    #page_content .manage_setup h1 {
        font-size: 20px;
        line-height: normal;
    }
}

@media(max-width:500.98px) {
    #page_content .mobile_first_banner h1 {
        font-size: 15px;
    }

    #page_content .mobile_first_banner p {
        font-size: 12px;
    }

    #page_content .mobile_first_banner {
        padding: 70px 0 30px;
    }

    #page_content .world h2 {
        font-size: 20px;
    }

    #page_content .world p {
        font-size: 12px;
    }

    #page_content .scribble h1 {
        font-size: 20px;
        padding-bottom: 20px
    }

    #page_content .featured h3 {
        font-size: 34px;
    }

    #page_content .imagination h1 {
        font-size: 18px;
    }
}

@media (max-width: 425.98px) {

    #page_content .shop h1,
    #page_content .upgrade h1,
    #page_content .fly h1 {
        font-size: 35px;
    }

    #page_content .fly h1,
    #page_content .shop h1,
    #page_content .upgrade h1 {
        font-size: 35px;
    }

    #page_content .sec_2 .card .card-body h4 {
        font-size: 16px;
    }

    #page_content .sec_2 .card .card-body p {
        font-size: 12px;
    }

    #page_content .sec_1 h1 {
        font-size: 24px;
    }

    #page_content .sec_3 img {
        margin: 10px;
    }

    #page_content .sec_3 h3,
    #page_content .sec_2 h3 {
        font-size: 40px;
        margin-bottom: 30px;
    }

    #page_content .sec_3 .div-block-25 {
        margin: 10px 0;
    }

    // {
    //   margin: 10px 0;
    // }
}

@media(max-width:375px) {
    #page_content .imagination h3 {
        font-size: 14px;
    }

    #page_content .imagination p {
        font-size: 12px;
    }

    #page_content .imagination {
        padding: 50px 0 300px;
    }

    #page_content .specification h3 {
        font-size: 35px;
    }

    #page_content .specification h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    #page_content .specification {
        padding: 35px 0;
    }

    #page_content .inspired h2 {
        font-size: 12px;
    }

    #page_content .inspired p {
        font-size: 10px;
        padding: 0;
    }

    #page_content .world h2 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 10px;
    }
}
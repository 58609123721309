.main-layout-wrapper {
    position: relative;
    min-height: 100%;
   
    text-size-adjust: none;
}
.column-parent {
    flex-grow: 1;
    display: flex;
    box-sizing: border-box;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.middle-aligner {
   
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
}
nav#side-menu {
    flex: 0 0 auto;
    position: fixed;
    width: 20vw;
    box-sizing: border-box;
    padding-top: 1.5rem;
    margin: 0;
    line-height: 1.3;
    font-family: TTNorms;
    z-index: 100;
    font-family: TTNorms, sans-serif;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: antialiased;
}
nav {
    display: block;
}

* {
    outline: none;
}
nav#side-menu .menu-wrapper {
    display: block;
    box-sizing: border-box;
    height: 90vh;
    overflow-y: auto;
    padding-bottom: 2vh;
    padding-right: 2rem;
}
@media only screen and (max-width: 1200pt){
.menu-wrapper {
    max-height: 80vh;
}}
nav#side-menu ol.menu-list {
    margin-top: 0;
    margin-bottom:px;
    padding-left: 0;
    list-style: none;
}
nav#side-menu ol.menu-list li.menu-item {
    display: flex;
    flex-direction: column;
    position: relative;
}
nav#side-menu ol.menu-list li.menu-item [class^="menu-list-opener"] {
    border: none;
    padding-left: 20px;
    text-align: left;
    padding-bottom: 15px;
    
    background-color: transparent;
    
    
    font-size: 18px;
  
    color: #616161;
    cursor: pointer;
}
nav#side-menu ol.menu-list li.menu-item [class^="menu-list-opener"], nav#side-menu ol.menu-list li.menu-item a {
    display: flex;
    margin-top: .625em;
   
    transition: color .125s;
    text-overflow: ellipsis;

    overflow: hidden;
}
nav#side-menu ol.menu-list li.menu-item a {
    text-decoration: none;
}

nav#side-menu ol.menu-list li.menu-item a {
    text-decoration: none;
}
nav#side-menu ol.menu-list li.menu-item [class^="menu-list-opener"], nav#side-menu ol.menu-list li.menu-item a {
    display: flex;
    margin-top: .625em;
    font-weight: 600;
    transition: color .125s;
    text-overflow: ellipsis;
    font-family: TTNorms, sans-serif !important;
    font-family: TTNorms;
    overflow: hidden;
    font-size: 15px;
}
main.content:not(:last-child) {
    margin-right: 20vw;
}
main.content {
  
    min-width: 0;
    margin-left: 20vw;
    padding-top: 4.5rem;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1.5rem;
    color: #222222;
    line-height: 1.5;
    -webkit-print-color-adjust: exact;

}
main.content .title-with-source-edit {
    display: flex;
font-size: 1.5rem;
    color: #222222;
    line-height: 1.5;
    -webkit-print-color-adjust: exact;
}

element.style {
}
main.content .title-with-source-edit h1 {
    flex: 1 1 auto;
}
main.content h1 {
    margin: 0 0 3rem;
    color: #683d87;
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 1.3;
}
main.content .title-with-source-edit h1 {
    flex: 1 1 auto;
}
main.content blockquote, main.content ol, main.content p, main.content ol {
    margin: 1em 0;
font-size: 15px;
    color: #222222;
    line-height: 1.5;
    -webkit-print-color-adjust: exact;
}
main.content h2 {
    margin: 3rem 0 1.6rem;
    font-size: 2.5rem;
    font-weight: bold;
    color: #616161;
    line-height: 1.4;
}
main.content h1, main.content h2, main.content h3, main.content h4 {
    letter-spacing: 0.2px;
}
nav#my-toc {
    position: absolute;
    right: 0;
    width: 20vw;
    flex: 0 0 auto;
}
.side-toc {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 20vw;
    margin-left: 0;
    margin-right: auto;
    font-family: TTNorms;
    padding-top: 4rem;
    padding-left: 2rem;
    position: fixed;
}
li.toc-entry.toc-h2 {
    list-style-type: none;
    font-size: 1.4rem;
    border-left: 2px solid #dbc3ed;
    padding-left: 2rem;
    line-height: 1.6;
    padding-bottom: 0.625rem;
}
li.toc-entry.toc-h2 {
    list-style-type: none;
    font-size: 1.4rem;
    border-left: 2px solid #dbc3ed;
    padding-left: 2rem;
    line-height: 1.6;
    padding-bottom: 0.625rem;
}

.side-toc:before {
    content: "Contents";
    display: block;
    padding-bottom: 0.825rem;
    padding-left: 2rem;
    font-weight: 600;
    font-size: 1.7rem;
    color: #683d87;
}
nav#side-menu div::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
}
nav#side-menu div::-webkit-scrollbar-thumb {
    background-color: #f8f0ff;
    border-radius: 4px;
}
nav#side-menu div::-webkit-scrollbar-track {
    display: none;
}
nav#side-menu .menu-wrapper {
 
    height: 90vh;
    overflow-y: auto;
    padding-bottom: 5vh;
    padding-right: 2rem;
}
nav#side-menu ol.menu-list li.menu-item li.menu-item, nav#side-menu ol.menu-list li.menu-item a {
    font-size:10px;
    line-height: 1.3;
    font-family: TTNorms;
    color:#000000;
}
.body {
    font-size: 62.5%;
    text-size-adjust: none;
}
.fontfixing{
    font-weight: 10 !important;
}
.Infrastructure_text{
   max-width: 465px;
}
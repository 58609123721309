.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-family: Lato,sans-serif;
    font-weight: 300;
    color: #666;
    
}
.blog-title {
    font-weight: 300;

    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.1;
    color: inherit;

}

@media only screen and (min-width: 992px) {
    .container {
        width: 970px;
      
    }
  }
  @media only screen and (min-width: 768px) {
    .container {
        width: 750px;
    }

  }
  @media only screen and (min-width: 992px) {
    .container, p {
        font-size: 18px;
        line-height: 1.5;
    }
  }
  @media only screen and (min-width: 768px) {
    .container, p {
        font-size: 16px;
    }
  }
  @media (min-width: 768px){
.blog-title-row {
    margin: 30px 0 0;
}
  }
  .btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
    display: table;
    content: " ";
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px){
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
}
}

@media (min-width: 992px){
.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    float: left;
}
}
@media (min-width: 992px){
.col-md-8 {
    width: 66.66666667%;
}
}
@media (min-width: 992px){
    .bodycol {
        width: 66.66666667%;
    }
    }
    @media (max-width: 575px){
        .bodycol {
        
           margin-right:auto;
           text-align: center;
           
        }
        }
     
@media (min-width: 992px){
.col-md-offset-2 {
    margin-left: 16.66666667%;
}
}
@media (min-width: 1200px){
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    float: left;
}
}
@media (min-width: 1200px){
.col-lg-8 {
    width: 66.66666667%;
}
}
@media (min-width: 1200px){
.col-lg-offset-2 {
    margin-left: 16.66666667%;
}
}
.big-detail-row {

}
@media (min-width: 768px){
.blog-detail-row {
    margin: 10px 0 50px;
}
}
.btn-group-vertical>.btn-group:after, .btn-toolbar:after, .clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .form-horizontal .form-group:after, .modal-footer:after, .nav:after, .navbar-collapse:after, .navbar-header:after, .navbar:after, .pager:after, .panel-body:after, .row:after {
    clear: both;
}
@media (min-width: 768px){
.blog-detail-body {
    font-size: 18px;
}
}
@media (min-width: 768px){
.blog-detail-body p {
    font-size: 18px;
    margin: 20px 0;
}
}
@media (min-width: 992px){
.col-md-8 {
    width: 66.66666667%;
}
}
.kg-image {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    display: block;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}
.blog-detail-body figure {
    margin: .8em 0 2.3em;
}
.strong {
    font-size:"30px";
    color:red;
}
.blog-detail-meta {
    font-size: 14px;
    color: #999;
}
@media (min-width: 992px){
.blog-detail-meta {
    text-align: right;
   
  
    margin-top: 25px;
}
}
@media (min-width: 992px){
.col-md-2 {
    width: 16.66666667%;
}
}

@media (min-width: 768px){
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
}
}
.blog-detail-meta {
    font-size: 14px;
    color: #999;
}
@media (min-width: 992px){
.blog-detail-meta {
    text-align: right;
  
}
}
.blog-detail-meta {
    font-size: 14px;
    color: #999;
}
@media (min-width: 1200px){
.hidden-lg {
    display: none!important;
}
}
.spacer-5 {
    width: 1px;
    height: 5px;
    clear: both;
}
@media (min-width: 1200px){
.visible-lg {
    display: block!important;
}
}
.spacer-10 {
    width: 1px;
    height: 10px;
   
    clear: both;
}
@media (min-width: 1200px){
.visible-lg {
    display: block!important;
}
}
.spacer-15 {
    width: 1px;
    height: 15px;
    display: block;
    clear: both;
}

@media (min-width: 992px){
.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    float: left;
}}
@media (min-width: 768px){
.blog-detail-row {
    margin: 10px 0 50px;
}
}
.img-blog {
    width: 100%;
   

}